import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled, { createGlobalStyle } from 'styled-components';
import Editor from "@monaco-editor/react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';


const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    background-color: #f0f2f5;
    margin: 0;
    padding: 0;
  }
`;

const AppContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h1`
  color: #2c3e50;
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5em;
  font-weight: 300;
`;

const MainLayout = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  height: 80vh;
`;

const Layout = styled.div`
  display: flex;
  gap: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const ChatSection = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;


const VideoSection = styled.div`
  flex: 1;
  background-color: #2c3e50;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const ChatContainer = styled.div`
  height: 400px;
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f8f9fa;
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Message = styled.div`
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 18px;
  max-width: 80%;
  line-height: 1.4;
  ${props => props.role === 'user' ? `
    background-color: #007bff;
    color: white;
    align-self: flex-end;
    margin-left: auto;
  ` : `
    background-color: #e9ecef;
    color: #343a40;
  `}
`;

const InputContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 10px 15px;
  border: 1px solid #ced4da;
  border-radius: 30px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.2s;

  &:focus {
    border-color: #007bff;
  }
`;

const CodeEditorContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
`;

const CodeEditorHeader = styled.h2`
  display: flex;
  color: #2c3e50;
  margin-bottom: 10px;
`;

const CodeBlock = styled(SyntaxHighlighter)`
  margin: 10px 0;
  border-radius: 5px;
`;

const Video = styled.video`
  width: 100%;
  border-radius: 5px;
  margin-top: 20px;
  transform: scaleX(-1);
`;

const MicStatus = styled.div`
  padding: 5px 10px;
  border-radius: 20px;
  background-color: ${props => props.isListening ? '#28a745' : '#dc3545'};
  color: white;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  transition: background-color 0.3s;
`;

const SpeakingIndicator = styled.div`
  width: 100%;
  height: 300px;
  background-image: url('ai_.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
  display: ${props => props.isSpeaking ? 'block' : 'none'};
`;

const NotSpeakingIndicator = styled.div`
  width: 100%;
  height: 300px;
  background-image: url('logo192.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
  display: ${props => !props.isSpeaking ? 'block' : 'none'};
`;

const TimerContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${props => props.timeRemaining <= 60 ? '#dc3545' : '#28a745'};
  color: white;
  padding: 10px 15px;
  border-radius: 20px;
  font-weight: bold;
  transition: background-color 0.3s;
  margin: 30px;
`;

const TextArea = styled.textarea`
  height: 100px;
  padding: 10px 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
  resize: vertical;
  min-height: 100px;
  max-height: 300px;
  margin-bottom: 10px;
  font-family: inherit;
  line-height: 1.5;

  &:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 5px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

function Timer({ timeRemaining }) {
  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;

  return (
    <TimerContainer timeRemaining={timeRemaining}>
      Time Remaining: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </TimerContainer>
  );
}


function InterviewPage() {
  const [sessionId, setSessionId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const chatContainerRef = useRef(null);
  const videoRef = useRef(null);
  const recognitionRef = useRef(null);
  const synthRef = useRef(window.speechSynthesis);
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [code, setCode] = useState('// Write your code here');
  const [timeRemaining, setTimeRemaining] = useState(15 * 60); // 15 minutes in seconds
  const timerRef = useRef(null);

  useEffect(() => {
    startInterview();
    startVideo();
    initializeSpeechRecognition();
    loadVoices();
    return () => {
      stopListening();
      if (synthRef.current.speaking) {
        synthRef.current.cancel();
      }
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].role === 'assistant') {
      speakMessage(messages[messages.length - 1].content);
    }
  }, [messages]);

  const loadVoices = () => {
    // Some browsers need a small delay before voices are available
    setTimeout(() => {
      const voices = synthRef.current.getVoices();
      console.log('Available voices:', voices);
    }, 100);
  };

  const initializeSpeechRecognition = () => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');
        setUserInput(transcript);
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error', event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        setIsListening(false);
      };

      recognitionRef.current = recognition;
    } else {
      console.log('Speech recognition not supported');
    }
  };


  const startListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.start();
      setIsListening(true);
    }
  };

  const stopListening = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  };

  const speakMessage = (message) => {
    if (!synthRef.current) {
      console.error('Speech synthesis not initialized');
      return;
    }
  
    if (synthRef.current.speaking) {
      console.log('Speech in progress, cancelling...');
      synthRef.current.cancel();
    }
    if (message !== '') {
      setIsSpeaking(true);
      const utterThis = new SpeechSynthesisUtterance(message);
      utterThis.onend = () => {
        setIsSpeaking(false);
        startListening();
      };
      utterThis.onerror = (event) => {
        console.error('SpeechSynthesisUtterance.onerror', event);
        setIsSpeaking(false);
      };
      // Set voice to a gentle male voice
      const voices = synthRef.current.getVoices();
      const maleVoice = voices.find(voice => voice.name.includes('Male') || voice.name.includes('male'));
      if (maleVoice) {
        utterThis.voice = maleVoice;
      }
      utterThis.pitch = 1;
      utterThis.rate = 0.9;
      synthRef.current.speak(utterThis);
      const resumeSpeakingHack = () => {
        if (synthRef.current.speaking) {
          console.log('Resuming speech (Chrome hack)');
          synthRef.current.pause();
          synthRef.current.resume();
          setTimeout(resumeSpeakingHack, 14000);
        } else {
          console.log('Speech completed or stopped');
          setIsSpeaking(false);
        }
      }

      setTimeout(resumeSpeakingHack, 100);

  }
  };

  const startInterview = async () => {
    try {
      const response = await axios.post(API_URL+'/api/start-interview');
      setSessionId(response.data.sessionId);
      setMessages([{ role: 'assistant', content: response.data.message }]);
      setTimeRemaining(15 * 60);
      startTimer();
    } catch (error) {
      console.error('Error starting interview:', error);
    }
  };

  const startTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(() => {
      setTimeRemaining(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          endInterview();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const endInterview = () => {
    stopListening();
    if (synthRef.current.speaking) {
      synthRef.current.cancel();
    }
    setMessages(prevMessages => [
      ...prevMessages,
      { role: 'assistant', content: "Time's up! The interview has ended. Thank you for your participation." }
    ]);
  };

  const startVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  const handleSubmit = async () => {
    if (!userInput.trim() || !sessionId) return;
    
    stopListening();
    setMessages(prev => [...prev, { role: 'user', content: userInput }]);
    setUserInput('');

    try {
      const response = await axios.post(API_URL+'/api/interview-response', {
        sessionId,
        userResponse: userInput
      });

      const aiMessage = response.data.message;
      setMessages(prev => [...prev, { role: 'assistant', content: response.data.message }]);

      // Check if the AI is asking for code implementation
      if (aiMessage.toLowerCase().includes("implement") || aiMessage.toLowerCase().includes("write the code")) {
        setShowCodeEditor(true);
      }

    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCodeChange = (value) => {
    setCode(value);
  };

  const submitCode = () => {
    const codeSubmission = `Code submission:\n\n${code}`;
    setShowCodeEditor(false);
    setUserInput(codeSubmission);
    handleSubmit();
  };

  const renderMessage = (message) => {
    if (message.content.startsWith('Code submission:')) {
      const [, code] = message.content.split('Code submission:\n\n');
      return (
        <>
          <p>Code submission:</p>
          <CodeBlock language="javascript" style={vscDarkPlus}>
            {code}
          </CodeBlock>
        </>
      );
    }
    return message.content;
  };

  return (
     <>
     <GlobalStyle />
    <AppContainer>
      <Header>CareerMast - DSA Interview</Header>
      <Timer timeRemaining={timeRemaining} />
      <MainLayout>
        <ChatSection>
          <ChatContainer ref={chatContainerRef}>
            {messages.map((message, index) => (
              <Message key={index} role={message.role}>
                {renderMessage(message)}
              </Message>
            ))}
          </ChatContainer>
            <InputContainer>
              <TextArea
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Type or speak your response..."
                onKeyPress={(e) => e.key === 'Enter' && handleSubmit()}
              />
              <ButtonContainer>
                  <Button onClick={handleSubmit} disabled={isSpeaking}>Send</Button>
              </ButtonContainer>            
              </InputContainer>
            <MicStatus isListening={isListening}>
                Mic: {isListening ? 'On' : 'Off'}
              </MicStatus>
            
        </ChatSection>
        <VideoSection>
          <SpeakingIndicator isSpeaking={isSpeaking} />
          <NotSpeakingIndicator isSpeaking={isSpeaking} />
          <Video ref={videoRef} autoPlay muted playsInline />
        </VideoSection>
        </MainLayout>
        {showCodeEditor && (
        <CodeEditorContainer>
          <CodeEditorHeader>Code Editor</CodeEditorHeader>
          <ButtonContainer>
            <Button onClick={submitCode}>Submit Code</Button>
          </ButtonContainer>
          <Editor
            height="300px"
            defaultLanguage="javascript"
            value={code}
            onChange={handleCodeChange}
            theme="vs-dark"
            options={{
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              fontSize: 14,
            }}
          />
        </CodeEditorContainer>
      )}
    </AppContainer>
    </>
  );
}

export default InterviewPage;  

