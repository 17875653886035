import React from 'react';
import styled from 'styled-components';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const ReportContainer = styled.div`
  max-width: 800px;
  margin: 40px auto;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const ReportHeader = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const ReportTitle = styled.h1`
  color: #2c3e50;
  margin-bottom: 10px;
`;

const CandidateName = styled.h2`
  color: #34495e;
`;

const Section = styled.div`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h3`
  color: #2980b9;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
  margin-bottom: 15px;
`;

const Rating = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const RatingLabel = styled.span`
  font-weight: bold;
`;

const RatingValue = styled.span`
  color: ${props => props.value >= 4 ? '#27ae60' : props.value >= 3 ? '#f39c12' : '#c0392b'};
`;

const Feedback = styled.p`
  line-height: 1.6;
  color: #34495e;
`;

const DownloadButton = styled.button`
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2980b9;
  }
`;

const CodeSection = styled.div`
  margin-top: 20px;
`;

const CodeTitle = styled.h4`
  color: #34495e;
  margin-bottom: 10px;
`;

Font.register({
    family: 'Roboto',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf'
  });
  
// Styles for PDF
const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      padding: 20,
      fontFamily: 'Roboto',
    },
    header: {
      marginBottom: 20,
      borderBottom: '1 solid #34495e',
      paddingBottom: 10,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      color: '#2c3e50',
    },
    subtitle: {
      fontSize: 18,
      textAlign: 'center',
      color: '#34495e',
      marginTop: 5,
    },
    section: {
      margin: 10,
      padding: 10,
    },
    sectionTitle: {
      fontSize: 16,
      color: '#2980b9',
      marginBottom: 5,
      borderBottom: '1 solid #3498db',
      paddingBottom: 5,
    },
    text: {
      fontSize: 12,
      marginBottom: 5,
      color: '#34495e',
    },
    rating: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
    ratingLabel: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    ratingValue: {
      fontSize: 12,
    },
    code: {
      fontSize: 10,
      fontFamily: 'Courier',
      backgroundColor: '#f6f8fa',
      padding: 10,
      marginTop: 5,
      borderRadius: 5,
    },
  });

// PDF Document component
const MyDocument = ({ data }) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>Interview Performance Report</Text>
          <Text style={styles.subtitle}>{data.name}</Text>
        </View>
  
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Overall Ratings</Text>
          <View style={styles.rating}>
            <Text style={styles.ratingLabel}>Overall Rating:</Text>
            <Text style={styles.ratingValue}>{data.overallRating}/5</Text>
          </View>
          <View style={styles.rating}>
            <Text style={styles.ratingLabel}>Communication:</Text>
            <Text style={styles.ratingValue}>{data.communicationRating}/5</Text>
          </View>
          <View style={styles.rating}>
            <Text style={styles.ratingLabel}>Problem Solving:</Text>
            <Text style={styles.ratingValue}>{data.problemSolvingRating}/5</Text>
          </View>
        </View>
  
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Communication Feedback</Text>
          <Text style={styles.text}>{data.communicationFeedback}</Text>
        </View>
  
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>DSA Question Feedback</Text>
          <Text style={styles.text}>{data.dsaFeedback}</Text>
        </View>
  
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Submitted Code</Text>
          <Text style={styles.code}>{data.submittedCode}</Text>
        </View>
      </Page>
    </Document>
  );

const InterviewReport = () => {
  // Dummy data
  const reportData = {
    name: "Ashish Chitravanshi",
    overallRating: 4,
    communicationRating: 4,
    problemSolvingRating: 4,
    communicationFeedback: "John demonstrated excellent communication skills throughout the interview. He articulated his thoughts clearly and concisely, asking relevant questions when necessary. His ability to explain complex concepts was particularly noteworthy.",
    dsaFeedback: "For the binary tree traversal question, John provided an efficient solution using a depth-first search approach. His code was well-structured and properly commented. He successfully handled edge cases and optimized the space complexity by using an iterative solution instead of a recursive one.",
    submittedCode: `
function inorderTraversal(root) {
    const result = [];
    const stack = [];
    let current = root;

    while (current || stack.length) {
        while (current) {
            stack.push(current);
            current = current.left;
        }
        current = stack.pop();
        result.push(current.val);
        current = current.right;
    }

    return result;
}
    `
  };

  return (
    <ReportContainer>
      <ReportHeader>
        <ReportTitle>Interview Performance Report</ReportTitle>
        <CandidateName>{reportData.name}</CandidateName>
      </ReportHeader>

      <Section>
        <SectionTitle>Overall Ratings</SectionTitle>
        <Rating>
          <RatingLabel>Overall Rating:</RatingLabel>
          <RatingValue value={reportData.overallRating}>{reportData.overallRating}/5</RatingValue>
        </Rating>
        <Rating>
          <RatingLabel>Communication:</RatingLabel>
          <RatingValue value={reportData.communicationRating}>{reportData.communicationRating}/5</RatingValue>
        </Rating>
        <Rating>
          <RatingLabel>Problem Solving:</RatingLabel>
          <RatingValue value={reportData.problemSolvingRating}>{reportData.problemSolvingRating}/5</RatingValue>
        </Rating>
      </Section>

      <Section>
        <SectionTitle>Communication Feedback</SectionTitle>
        <Feedback>{reportData.communicationFeedback}</Feedback>
      </Section>

      <Section>
        <SectionTitle>DSA Question and Code Feedback</SectionTitle>
        <Feedback>{reportData.dsaFeedback}</Feedback>
        <CodeSection>
          <CodeTitle>Submitted Code:</CodeTitle>
          <SyntaxHighlighter language="javascript" style={vscDarkPlus}>
            {reportData.submittedCode}
          </SyntaxHighlighter>
        </CodeSection>
      </Section>

      <PDFDownloadLink document={<MyDocument data={reportData} />} fileName="interview_report.pdf">
        {({ blob, url, loading, error }) => (
          <DownloadButton>
            {loading ? 'Generating PDF...' : 'Download PDF Report'}
          </DownloadButton>
        )}
      </PDFDownloadLink>
    </ReportContainer>
  );
};

export default InterviewReport;
