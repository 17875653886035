import React from 'react';
import { BrowserRouter as Router, Route, Routes as Switch } from 'react-router-dom';
import InterviewPage from './InterviewPage'; // Your main interview component
import InterviewReport from './InterviewReport'; // The report component we created earlier
import LoginPage from './LoginPage'; // The report component we created earlier


function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" element={<LoginPage/>} />
        <Route exact path="/interview" element={<InterviewPage/>} />
        <Route path="/report" element={<InterviewReport/>} />
      </Switch>
    </Router>
  );
}

export default App;