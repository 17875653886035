import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f7fa;
  font-family: 'Arial', sans-serif;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 1000px;
  height: 600px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ImageSection = styled.div`
  flex: 1;
  background-image: url('interview-6956089_1920.png');
  background-size: cover;
  background-position: center;
`;

const LoginSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
`;

const Welcome = styled.h1`
  color: #333;
  margin-bottom: 30px;
  font-size: 24px;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: #0077ff;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #0077ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0066dd;
  }
`;

function LoginPage() {
  const [passphrase, setPassphrase] = useState('');
  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passphrase.trim() && passphrase==='auniquepassphrase') {
      history('/interview');
    } else {
      alert('Please enter a valid passphrase');
    }
  };

  return (
    <PageContainer>
      <ContentContainer>
        <ImageSection />
        <LoginSection>
          <Welcome><p>Welcome!</p>Please enter the passphrase to begin your interview.</Welcome>
          <form onSubmit={handleSubmit} style={{width: '100%'}}>
            <Input
              type="password"
              value={passphrase}
              onChange={(e) => setPassphrase(e.target.value)}
              placeholder="Enter passphrase"
            />
            <Button type="submit">Start Interview</Button>
          </form>
        </LoginSection>
      </ContentContainer>
    </PageContainer>
  );
}

export default LoginPage;

